<template>
    <div class="guide btm-menu">

        <div class="section guide-main">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-3 d-lg-none">
                        <div class="guide-top">
                            <div class="guide-top__img">
                                <img src="/assets/icons/ratgeber-thumb.png" alt="Ratgeber">
                            </div>
                            <div class="guide-top__info">
                                <h3>Alle infos für Dich auf einen Blick.</h3>
                            </div>
                        </div>
                        <p class="guide-info">In unserem Ratgeber findest du Artikel für verschiedene
                            Interessensgruppen:</p>
                    </div>
                    <div class="col-lg-4 d-none d-lg-block">
                        <div class="guide-top">
                            <div class="guide-top__img">
                                <img src="/assets/icons/ratgeber.png" alt="Ratgeber">
                            </div>
                            <div class="guide-top__info">
                                <h1 class="logo">Ratgeber</h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-8">

                        <div class="row categorie-cards">
                            <div class="col-12 d-none d-lg-block mb50">
                                <h3 class="mb20 font24 text-uppercase">Alle infos für Dich auf einen blick.</h3>
                                <p class="text-black font14">In unserem Ratgeber findest du Artikel für verschiedene
                                    Interessensgruppen:</p>
                            </div>
                            <div class="col-12 col-md-6 col-xl-5"
                                title="Ratgeberartikel zu dem Thema: Neu in Wolfsburg">
                                <router-link :to="getRatgeberCategoryLink('Neu in Wolfsburg')" class="card bg-meganta">
                                    <div class="card__img"><img src="/assets/icons/Integration.svg"
                                            alt="Neu in Wolfsburg"></div>
                                    <div class="card__content">
                                        <h3>Neu in Wolfsburg</h3>
                                        <p>Infos für Neubürger*innen und Geflüchtete.</p>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-12 col-md-6 col-xl-5" title="Ratgeberartikel zu dem Thema: Senior*innen">
                                <router-link :to="getRatgeberCategoryLink('Senior*innen')" class="card bg-purple">
                                    <div class="card__img"><img src="/assets/icons/alter.svg" alt="Senior*innen"></div>
                                    <div class="card__content">
                                        <h3>Senior*innen</h3>
                                        <p>Tipps &amp; Tricks für ein erfülltes Leben im Alter.</p>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-12 col-md-6 col-xl-5" title="Ratgeberartikel zu dem Thema: Erwachsene">
                                <router-link :to="getRatgeberCategoryLink('Erwachsene')" class="card bg-blue">
                                    <div class="card__img"><img src="/assets/icons/Erwachsene.svg" alt="Eltern"></div>
                                    <div class="card__content">
                                        <h3>Erwachsene</h3>
                                        <p>Praktische Infos für den Alltag im Erwachsenenleben.</p>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-12 col-md-6 col-xl-5" title="Ratgeberartikel zu dem Thema: Eltern">
                                <router-link :to="getRatgeberCategoryLink('Eltern')" class="card bg-jade">
                                    <div class="card__img"><img src="/assets/icons/Familie.svg" alt="Eltern"></div>
                                    <div class="card__content">
                                        <h3>Eltern</h3>
                                        <p>Tipps & Ratschläge für Eltern und Erziehungsberechtige.</p>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-12 col-md-6 col-xl-5"
                                title="Ratgeberartikel zu dem Thema: Kinder und Jugendliche">
                                <router-link :to="getRatgeberCategoryLink('Kinder & Jugendliche')"
                                    class="card bg-orange">
                                    <div class="card__img"><img src="/assets/icons/KinderJugendliche.svg"
                                            class="kids-img" alt="Kinder&amp; Jugendliche"></div>
                                    <div class="card__content">
                                        <h3>Kinder &amp; Jugendliche</h3>
                                        <p>Infos und Hilfestellungen für Kinder & Jugendliche.</p>
                                    </div>
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="section d-lg-block interesting-articles">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="section-head">
                            <h3 class="section-head__title">Wusstest du schon...?</h3>
                            <h4 class="section-head__info">Hilfreiche Tipps aus unserem Ratgeber:</h4>
                        </div>
                    </div>
                </div>

                <div class="row mobile--scrolling">
                    <ratgeber-card v-for="(item, index) in articles" :content="item" :key="index" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getContentsOfType } from '@/api/content';
import { showLoader, hideLoader } from '@/utils/helpers';

export default {
    name: 'Guide',
    components: {
        Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        KategorieCard: () => import('@/components/cards/Kategorie.vue'),
        RatgeberCard: () => import('@/components/cards/Ratgeber.vue'),
    },
    data() {
        return {
            loader: null,
            dataSet: {
                total: 0,
                data: null,
                meta: null,
                query: {
                    page: 1,
                    limit: 4,
                    selectedAppId: this.$appId,
                    keyword: '',
                    type: 'teaser',
                    selectRandomly: true,
                    selectedContentTypeId: this.$ratgeberId,
                    view_status: 1,
                    sort: {
                        prop: '',
                        order: 'asc'
                    },
                },
            },
        }
    },
    created() {
        this.getRatgeberSelection();
    },
    computed: {
        articles() {
            if (this.dataSet.data != null && this.dataSet.data.length > 0) {
                /*hard-coded Langcode*/
                return this.dataSet.data;
            }
            return null;
        },

    },
    methods: {
        showLoader,
        hideLoader,
        async getRatgeberSelection() {
            const { limit, page } = this.dataSet.query;
            const { data, meta } = await getContentsOfType(this.$ratgeberId, this.dataSet.query);
            this.dataSet.data = data;
            this.dataSet.data.forEach((element, index) => {
                element['index'] = (page - 1) * limit + index + 1;
            });
            this.dataSet.meta = meta;
            this.dataSet.total = meta.total;
        },
        getRatgeberCategoryLink(category) {
            var cat = "default";
            if (category !== null && category !== undefined) {
                cat = category.replaceAll(' ', '');
            }
            return "/ratgeber/" + cat;
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../scss/_variables.scss';

.guide-top__info h3 {
    font-weight: bold !important;
}

.interesting-articles {
    .mobile--scrolling {
        width: auto;
    }
}

.kids-img svg {
    fill: orange;
}

.categorie-cards {

    .card {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 125px;
        height: auto;
        background-color: $primary;
        vertical-align: middle;
        box-shadow: 1px 2px 4px 0px #00000040;
        margin-bottom: 15px;
        border: none;
        border-radius: 0;

        &__img {
            background: $white-color;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 125px;
            height: 125px;

            img {
                height: 65%;
                width: auto;
            }

        }

        &__content {
            padding: 0 15px;
            color: $white-color;

            h3 {
                color: $white-color;
                margin-bottom: 10px;
                font-size: 16px;
            }

            p {
                font-size: 13px;
            }
        }
    }
}
</style>